import BaseModel from "./BaseModel";

export default class Address extends BaseModel {
    private _contact_person: string = "";
    private _phone: string = "";
    private _street: string = "";
    private _street_number: string = "";
    private _city: string = "";
    private _postcode: string = "";
    private _country: string = "";
    private _type: string = "billing";
    private _latitude: number;
    private _longitude: number;

    get contact_person(): string {
        return this._contact_person;
    }

    set contact_person(value: string) {
        this._contact_person = value;
    }

    get phone(): string {
        return this._phone;
    }

    set phone(value: string) {
        this._phone = value;
    }

    get street(): string {
        return this._street;
    }

    set street(value: string) {
        this._street = value;
    }

    get street_number(): string {
        return this._street_number;
    }

    set street_number(value: string) {
        this._street_number = value;
    }

    get city(): string {
        return this._city;
    }

    set city(value: string) {
        this._city = value;
    }

    get postcode(): string {
        return this._postcode;
    }

    set postcode(value: string) {
        this._postcode = value;
    }

    get country(): string {
        return this._country;
    }

    set country(value: string) {
        this._country = value;
    }

    get type(): string {
        return this._type;
    }

    set type(value: string) {
        this._type = value;
    }

    get latitude(): number {
        return this._latitude;
    }

    set latitude(value: number) {
        this._latitude = value;
    }

    get longitude(): number {
        return this._longitude;
    }

    set longitude(value: number) {
        this._longitude = value;
    }
}
