import {ref} from "vue";
import {useState} from "#app";

export function useStateSocket() {
    const unread_message_count = useState('socket__unread_message_count', () => 0);
    const socket_reconnecting = useState('socket__socket_reconnecting', () => false);
    const unread_message_count_rooms = useState('socket__unread_message_count_rooms', () => {
        return {}
    });

    const setUnreadMessageRoomsCount = (room: string, count: number) => {
        unread_message_count_rooms.value[room] = count;
    }
    return {unreadMessageCount: unread_message_count, unreadMessageCountRooms: unread_message_count_rooms, setUnreadMessageRoomsCount, socket_reconnecting}
}
