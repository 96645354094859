import {defineNuxtPlugin} from '#imports'
import {useState} from "#app";

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.$router.options.scrollBehavior = async (to, from, savedPosition: { top: number, behavior: string, el: string }) => {
        let goTo: { top: number, behavior: string, el: string | undefined } = {};

        if (to.hash) {
            goTo = {
                el: to.hash,
                behavior: 'smooth',
                top: 64,
            }
        } else if (savedPosition) {
            goTo = savedPosition
        } else if(to.path != from.path) {
            goTo = {top: 0, behavior: 'smooth'}
        }

        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(goTo);
            }, 400)
        })
    }
})
