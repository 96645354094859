import BaseModel from "./BaseModel";

export default class SimpleClientFollower extends BaseModel {
  private _id_following_client: number = -1;
  private _notify: boolean = false;

  get id_following_client (): number {
    return this._id_following_client;
  }

  set id_following_client (value: number) {
    this._id_following_client = value;
  }

  get notify (): boolean {
    return this._notify;
  }

  set notify (value: boolean) {
    this._notify = value;
  }
}
