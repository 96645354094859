import {io, Socket} from "socket.io-client";
import {useRuntimeConfig, defineNuxtPlugin, useState} from "#app";
import {useAuthUser} from "../composables/auth/useAuthUser";
import {useStateSocket} from "../composables/state/socket";

const loadUnreadMessagesCount = (client, state, $socketApi) => {

    $socketApi.getSocketUrl(`/api/chat/unread_count`, client.socket_token).then(res => {
        if (res.data && !isNaN(res.data.total_count)) {
            state.unreadMessageCount.value = res.data.total_count;
        }
    });
}

const loadRoomUnreadMessages = (roomCode, state, $socketApi) => {

    $socketApi.getSocketUrl(`/api/chat/unread_count`, {query: {room: roomCode}}).then(res => {
        if (res.data && !isNaN(res.data.total_count)) {
            const re = {};
            re[roomCode] = res.data.total_count;

            state.setUnreadMessageRoomsCount(roomCode, res.data.total_count);
            return true;
        }
    });


};

const initWSGlobal = (socket, client, state, $socketApi) => {
    loadUnreadMessagesCount(client, state, $socketApi);
    socket.on('messenger_message_receive_g', setTimeout(() => loadUnreadMessagesCount(client, state, $socketApi), 223));
    socket.on('read_message', (data) => {
        if (data.idr) {
            setTimeout(() => {
                loadRoomUnreadMessages(data.idr, state, $socketApi);
            }, 843);
        }
        setTimeout(() => {
            loadUnreadMessagesCount(client, state, $socketApi)
        }, 1242);
    });
}

export default defineNuxtPlugin((nuxtApp) => {
    const user = useAuthUser();

    const socket = useState<null | Socket>('socketIo', () => null);
    const reconnect = () => {
        socket.value = io(useRuntimeConfig().public.SOCKIO_IO_URL,
            {path: '/ws1', reconnection: false}
        );
        socket.value.on("connect", () => {
            setTimeout(() => {
                const socketState = useStateSocket();
                initWSGlobal(socket.value, user, socketState, nuxtApp.$socketApi);
            }, 1500);
        });
    }

    const disconnect = () => {
        socket.value = null;
        const socketState = useStateSocket();
        socketState.unreadMessageCount.value = 0;
    }

    if (user.value) {
        reconnect();
    }

    return {
        provide: {
            socketIo: {socket: socket, reconnect: reconnect, disconnect: disconnect}
        }
    }
});
