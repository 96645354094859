import BaseModel from "./BaseModel";
import Client from "./Client";
// @ts-ignore
import dayjs from "dayjs";

export default class ClientNotification extends BaseModel {
  private _id: number;
  private _text: string = "";
  private _link: string = "";
  private _type: string = "";
  private _custom_type: string = "";
  private _seen: boolean = false;
  private _id_origin_client: number | null = null;
  private _origin_client: Client = new Client();
  private _created_at: Date | null = null;
  public removed: boolean = false;

  get id (): number {
    return this._id;
  }

  set id (value: number) {
    this._id = value;
  }

  get text (): string {
    return this._text;
  }

  set text (value: string) {
    this._text = value;
  }

  get link (): string {
    return this._link;
  }

  set link (value: string) {
    this._link = value;
  }

  get type (): string {
    return this._type;
  }

  set type (value: string) {
    this._type = value;
  }

  get seen (): boolean {
    return this._seen;
  }

  set seen (value: boolean) {
    this._seen = value;
  }

  get id_origin_client (): number | null {
    return this._id_origin_client;
  }

  set id_origin_client (value: number | null) {
    this._id_origin_client = value;
  }

  get created_at (): Date | null {
    return this._created_at;
  }

  set created_at (value: Date | null) {
    this._created_at = value;
  }

  get formattedCreatedAt (): dayjs.Dayjs {
    return dayjs(this.created_at ? this.created_at : (new Date()));
  }

  get origin_client (): Client {
    return this._origin_client;
  }

  set origin_client (value: Client) {
    this._origin_client = value;
  }

  get custom_type (): string {
    return this._custom_type;
  }

  set custom_type (value: string) {
    this._custom_type = value;
  }
}
