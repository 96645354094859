import BaseModel from "./BaseModel";

export default class ClientCredit extends BaseModel {
  private _id: number = -1;
  private _value: number = 0;
  private _exp_tim: Date = new Date();

  get id (): number {
    return this._id;
  }

  set id (value: number) {
    this._id = value;
  }

  get value (): number {
    return Number(this._value);
  }

  set value (value: number) {
    this._value = isNaN(value) ? 0 : Number(value);
  }

  get exp_tim (): Date {
    return this._exp_tim;
  }

  set exp_tim (value: Date) {
    this._exp_tim = value;
  }
}
