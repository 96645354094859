export enum TyrionResourceImageType {
    w300 = "w300",
    w500 = "w500",
    w700 = "w700",
    w900 = "w900",
    w1200 = "w1200",
    w1400 = "w1400",
    w1920 = "w1920",
    Thumb = "thumb",
    ThumbWatermark = "thumbw",
    ThumbM = "thumb_m",
    ThumbMWatermark = "thumb_mw",
    RealSrc = "real",
    CacheSrc = "cache",
    CacheSrcWatermark = "cachew"
}
