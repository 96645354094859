import components_plugin_KR1HBZs4kY from "/var/www/hladamchatu.sk/nuxt/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/var/www/hladamchatu.sk/nuxt/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/www/hladamchatu.sk/nuxt/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_5tzzN0oIVL from "/var/www/hladamchatu.sk/nuxt/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_UciE0i6zes from "/var/www/hladamchatu.sk/nuxt/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _1_inscriptions_107RNqunqp from "/var/www/hladamchatu.sk/nuxt/plugins/1.inscriptions.ts";
import auth_vT9JWWT9pN from "/var/www/hladamchatu.sk/nuxt/plugins/auth.ts";
import bootstrap_client_Uyd73t1ylZ from "/var/www/hladamchatu.sk/nuxt/plugins/bootstrap.client.ts";
import device_PxhEqcDcOg from "/var/www/hladamchatu.sk/nuxt/plugins/device.ts";
import directives_8CcCirWtnE from "/var/www/hladamchatu.sk/nuxt/plugins/directives.ts";
import google_recaptcha_rYK9Kziv68 from "/var/www/hladamchatu.sk/nuxt/plugins/google-recaptcha.ts";
import routeScroll_rP87Crtc9N from "/var/www/hladamchatu.sk/nuxt/plugins/routeScroll.ts";
import socket_client_qBDI6bzE0L from "/var/www/hladamchatu.sk/nuxt/plugins/socket.client.ts";
import socketApi_6C9Sw7ITCy from "/var/www/hladamchatu.sk/nuxt/plugins/socketApi.ts";
import splide_client_tQKGW7GEcx from "/var/www/hladamchatu.sk/nuxt/plugins/splide.client.js";
import tyGlobalFunctions_client_JEDlfbsKDq from "/var/www/hladamchatu.sk/nuxt/plugins/tyGlobalFunctions.client.ts";
import tyGlobalFunctions_15zhUMxhyn from "/var/www/hladamchatu.sk/nuxt/plugins/tyGlobalFunctions.ts";
import tyrionApi_glCLgKnppQ from "/var/www/hladamchatu.sk/nuxt/plugins/tyrionApi.ts";
export default [
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  prefetch_client_5tzzN0oIVL,
  chunk_reload_client_UciE0i6zes,
  _1_inscriptions_107RNqunqp,
  auth_vT9JWWT9pN,
  bootstrap_client_Uyd73t1ylZ,
  device_PxhEqcDcOg,
  directives_8CcCirWtnE,
  google_recaptcha_rYK9Kziv68,
  routeScroll_rP87Crtc9N,
  socket_client_qBDI6bzE0L,
  socketApi_6C9Sw7ITCy,
  splide_client_tQKGW7GEcx,
  tyGlobalFunctions_client_JEDlfbsKDq,
  tyGlobalFunctions_15zhUMxhyn,
  tyrionApi_glCLgKnppQ
]