import BaseModel from "./BaseModel";
import MetaTag from "./MetaTag";
import Image from "./Image";
import Tag from "./Tag";

export default class Page extends BaseModel {
    private _id: number = 0;
    private _title: string = "";
    private _title2: string = "";
    private _desc2: string = "";
    private _link: string = "";
    private _metaTags: Array<MetaTag> = [];
    private _images: Array<Image> = [];
    private _tags: Array<Tag> = [];

    public getItemMeta(type: string, key: string): MetaTag {
        let metaTag = this.metaTags.find(metaTag => metaTag.type === type && metaTag.key === key);
        metaTag = metaTag === undefined ? (new MetaTag()) : metaTag;
        return metaTag;
    }

    public fromObject(jsonObj: object): BaseModel {
        return super.fromObject(jsonObj, {
            images: (item => {
                return (new Image()).fromObject(item);
            }),
            tags: (item => {
                return (new Tag()).fromObject(item);
            })
        });
    }

    public getLinkWithSlash(): string {
        if (this.link && this.link.indexOf("http") > -1) {
            return this.link;
        } else if (this.link && this.link.length > 1 && this.link.charAt(0) === "/") {
            return this.link;
        }
        return `/${this.link}`;
    }

    public hasImage() {
        return this.images.length > 0;
    }

    public getTopImage() {
        return this.images[0];
    }

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get title(): string {
        return this._title;
    }

    set title(value: string) {
        this._title = value;
    }

    get link(): string {
        return this._link;
    }

    set link(value: string) {
        this._link = value;
    }

    get metaTags(): Array<MetaTag> {
        return this._metaTags;
    }

    set metaTags(value: Array<MetaTag>) {
        this._metaTags = value;
    }

    get title2(): string {
        return this._title2;
    }

    set title2(value: string) {
        this._title2 = value;
    }

    get desc2(): string {
        return this._desc2;
    }

    set desc2(value: string) {
        this._desc2 = value;
    }

    get images(): Array<Image> {
        return this._images;
    }

    set images(value: Array<Image>) {
        this._images = value;
    }

    get tags(): Array<Tag> {
        return this._tags;
    }

    set tags(value: Array<Tag>) {
        this._tags = value;
    }
}
