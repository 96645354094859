import BaseModel from "./BaseModel";

export default class Tag extends BaseModel {
  private _id: number;
  private _name: string;
  private _code: string;
  private _color: string;
  private _type: string;
  private _tag_order: number;

  get id (): number {
    return this._id;
  }

  set id (value: number) {
    this._id = value;
  }

  get name (): string {
    return this._name;
  }

  set name (value: string) {
    this._name = value;
  }

  get color (): string {
    return this._color;
  }

  set color (value: string) {
    this._color = value;
  }

  get type (): string {
    return this._type;
  }

  set type (value: string) {
    this._type = value;
  }

  get code (): string {
    return this._code;
  }

  set code (value: string) {
    this._code = value;
  }

  get tag_order (): number {
    return this._tag_order;
  }

  set tag_order (value: number) {
    this._tag_order = value;
  }
}
