import {computed, ref, watch} from "vue";
import Accommodation from "../../lib/models/Accommodation";
import {useState} from "#app";
import {ClientFavoriteItem} from "../../lib/models/ClientFavoriteItem";

export function useStateFunctionFavorites() {

    const favorites = useState<Array<ClientFavoriteItem>>('favorites', () => []);

    const addToFavorite = (obj: ClientFavoriteItem) => {
        favorites.value.push(obj);
    };

    const favoriteCount = computed(() => {
        return favorites.value.length;
    })

    const removeFromFavorite = (obj: any) => {
        favorites.value = favorites.value.filter(item => {
            if (item.hasOwnProperty("id") && obj.hasOwnProperty("id")) {
                return item.id !== obj.id;
            } else if (item.hasOwnProperty("_id") && obj.hasOwnProperty("_id")) {
                return item._id !== obj._id;
            } else {
                return JSON.stringify(item) !== JSON.stringify(obj);
            }
        });
    }

    return {favorites: favorites, favoriteCount, addToFavorite, removeFromFavorite}
}
