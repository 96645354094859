import supportsWebP from "supports-webp";
import {defineNuxtPlugin} from "#app";


export default defineNuxtPlugin(nuxtApp => {

    return {
        provide: {
            showWebp: async () => await supportsWebP
        }
    }
});
