import {latinise} from "./latinize";
// @ts-ignore
import dayjs from "dayjs";
// @ts-ignore
import striptags from "striptags";
import locales from '~/lib/data/locales.json';


interface LocaleData {
    code: string;
    iso: string;
    name: string;
    country: string;
    enabled: boolean;
    locale: string;
    currency: string;
}

function replaceEmptyValue(valueA: any, valueB: any, valueC: any = undefined, finalValue = "") {
    let value = (valueA === null || valueA === undefined) ? valueB : valueA;
    value = (value === null || value === undefined) ? valueC : value;
    value = (value === null || value === undefined) ? finalValue : value;
    return value;
}

function capitalizeFirstLetter(text: string) {
    return (text === undefined || text === null) ? "" : text.charAt(0).toUpperCase() + text.slice(1);
}

function timeSince(date: Date) {

    const seconds = Math.floor(((new Date()).getTime() - date.getTime()) / 1000);

    let interval = Math.floor(seconds / 31536000);

    if (interval > 1) {
        return interval + " years";
    }
    interval = Math.floor(seconds / 2592000);
    if (interval > 1) {
        return interval + " months";
    }
    interval = Math.floor(seconds / 86400);
    if (interval > 1) {
        return interval + " days";
    }
    interval = Math.floor(seconds / 3600);
    if (interval > 1) {
        return interval + " hours";
    }
    interval = Math.floor(seconds / 60);
    if (interval > 1) {
        return interval + " minutes";
    }
    return Math.floor(seconds) + " seconds";
}


// @ts-ignore
function arraysEqual(_arr1, _arr2) {

    if (!Array.isArray(_arr1) || !Array.isArray(_arr2) || _arr1.length !== _arr2.length) {
        return false;
    }

    const arr1 = _arr1.concat().sort();
    const arr2 = _arr2.concat().sort();

    for (let i = 0; i < arr1.length; i++) {

        if (arr1[i] !== arr2[i]) {
            return false;
        }

    }

    return true;
}

function generateRandomString(length: number = 8) {
    let text = "";
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
}

function formatCellphone(phone: string | number): string {

    if (phone === undefined) {
        return "";
    }
    const cleaned = ("" + phone).replace(" ", "");
    let match = cleaned.match(/^(\+)?(\d{3})(\d{3})(\d{3})(\d{3})$/);
    if (match) {
        if (match[2] === "421") {
            // return ["0", match[3], " ", match[4], " ", match[5]].join("");
        }
        return ["+", match[2], " ", match[3], " ", match[4], " ", match[5]].join("");
    }
    match = cleaned.match(/^(\d{4})(\d{3})(\d{3})$/);
    if (match) {
        return [match[1], " ", match[2], " ", match[3]].join("");
    }
    return phone.toString();
}

function hrefCellphone(phone: string | number): string {

    if (phone === undefined) {
        return "";
    }
    let hPhone = formatCellphone(phone);
    hPhone = (hPhone === null || hPhone === undefined) ? "" : hPhone;
    hPhone = hPhone.replace(/ /g, "");
    if (hPhone.indexOf("09") === 0) {
        hPhone = "+421" + hPhone.substring(1);
    } else if (hPhone.indexOf("42") === 0) {
        hPhone = "+" + hPhone;
    }
    return hPhone;
}

function numberWithSpaces(x: string) {
    const parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(".");
}

function clearObserverObj(object: {}): { [k: string]: any } {
    if (object === null || object === undefined) {
        return {};
    }
    return JSON.parse(JSON.stringify(object));
}

function replaceDashedKeys(object: {}): { [k: string]: any } {
    Object.keys(object).forEach((key) => {
        const value = object[key];
        if (key.charAt(0) === "_") {
            delete object[key];
            object[key.substring(1)] = value;
        }
    });
    return object;
}

function removeEmptyKeyValues(object: {}): { [k: string]: any } {
    if (object === null || object === undefined) {
        return {};
    }
    Object.keys(object).forEach((key) => {
        if (object[key] === null || object[key] === undefined) {
            delete object[key];
        } else if (typeof object[key] === "string" && object[key].length === 0) {
            delete object[key];
        }
    });
    return object;
}

function wait(ms: number) {
    return (x) => {
        return new Promise(resolve => setTimeout(() => resolve(x), ms));
    };
}

function formatTime(time: Date, format: string) {
    return dayjs(time).format(format);
}

function getDistance(origin: number[], destination: number[]) {
    // return distance in meters
    const lon1 = toRadian(origin[1]);
    const lat1 = toRadian(origin[0]);
    const lon2 = toRadian(destination[1]);
    const lat2 = toRadian(destination[0]);

    const deltaLat = lat2 - lat1;
    const deltaLon = lon2 - lon1;

    const a = Math.pow(Math.sin(deltaLat / 2), 2) + Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(deltaLon / 2), 2);
    const c = 2 * Math.asin(Math.sqrt(a));
    const EARTH_RADIUS = 6371;
    return c * EARTH_RADIUS * 1000;
}

function toRadian(degree: number) {
    return degree * Math.PI / 180;
}

function createWebUrl(text: string, latinize = true): string {
    if (text !== undefined && text !== null) {
        text = text.toLowerCase();
        text = text.replace(/[ _]/g, "-");
        text = text.replace(/[\.\(\)\:\,]/g, "");
        while (text.includes("--")) {
            text = text.replace("--", "-");
        }
        if (latinize) {
            text = latinise(text);
        }
        return text;
    }
    return "";
}

function buildUrlFromParams(params: Record<string, any>): string {


    function flattenParams(obj, parentKey = '', result = {}) {
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                const fullKey = parentKey ? `${parentKey}[${key}]` : key;
                if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
                    flattenParams(obj[key], fullKey, result);
                } else if (Array.isArray(obj[key])) {
                    obj[key].forEach((item, index) => {
                        if (typeof item === 'object') {
                            flattenParams(item, `${fullKey}[${index}]`, result);
                        } else {
                            result[`${fullKey}[${index}]`] = item;
                        }
                    });
                } else {
                    result[fullKey] = obj[key];
                }
            }
        }
        return result;
    }

    const queryString = new URLSearchParams(flattenParams(params)).toString();
    return `?${queryString}`;
}


function roundNumber(number: number, precision: number) {
    const factor = Math.pow(10, precision);
    const tempNumber = number * factor;
    const roundedTempNumber = Math.round(tempNumber);
    return roundedTempNumber / factor;
}

function openUrl(path: string, nuxt: any) {
    nuxt.$router.push({path: path});
}

function stripTags(text: string | null | undefined, letBt: boolean = false): string {
    if (text) {
        if (letBt) {
            return striptags(text).replace(/(?:\r\n|\r|\n)/g, "<br />");
        } else {
            return striptags(text);
        }
    }
    return "";
}

function isIntersectionObserverEnabled(): boolean {
    try {
        if (!("IntersectionObserver" in window) ||
            !("IntersectionObserverEntry" in window) ||
            // @ts-ignore
            !("intersectionRatio" in window.IntersectionObserverEntry.prototype)) {
            return false;
        } else {
            return true;
        }
    } catch (e) {
        return false;
    }

}

function stringlifyObject(obj) {
    const keys = Object.keys(obj);
    keys.forEach(function (key) {
        const value = obj[key];
        if (value && typeof value === "object") {
            return stringlifyObject(obj[key]);
        } else if (typeof value !== "string" && value && value.constructor !== Array) {
            obj[key] = String(value);
        }
    });
    return obj;
}

function getDefaultLocaleCurrency(localeCode: string, currencies: Array<{
    code: string,
    defaultLocale: Array<string>
}>): { code: string, mark: string, creditConversion: number } {
    const defaultCurrency = currencies.find(c => c.defaultLocale.some(d => d === localeCode));
    return defaultCurrency ? defaultCurrency : currencies.find(c => c.code === 'EUR');
}

function compareObjects(objA, objB): boolean {
    return JSON.stringify(stringlifyObject(clearObserverObj(objA))) === JSON.stringify(stringlifyObject(clearObserverObj(objB)));
}

function scrollToTop() {
    const c = document.documentElement.scrollTop || document.body.scrollTop;
    if (c > 0) {
        window.requestAnimationFrame(scrollToTop);
        window.scrollTo(0, c - c / 8);
    }
}

function validateEmail(email: string): boolean {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

function tyrionTrim(str: string, charlist?: string | undefined) {

    const whitespace = [
        " ",
        "\n",
        "\r",
        "\t",
        "\f",
        "\x0b",
        "\xa0",
        "\u2000",
        "\u2001",
        "\u2002",
        "\u2003",
        "\u2004",
        "\u2005",
        "\u2006",
        "\u2007",
        "\u2008",
        "\u2009",
        "\u200a",
        "\u200b",
        "\u2028",
        "\u2029",
        "\u3000"
    ].join("");
    let l = 0;
    let i = 0;
    str += "";

    if (charlist) {
        const whitespace = (charlist + "").replace(/([[\]().?/*{}+$^:])/g, "$1")
    }

    l = str.length;
    for (i = 0; i < l; i++) {
        if (whitespace.indexOf(str.charAt(i)) === -1) {
            str = str.substring(i);
            break
        }
    }

    l = str.length;
    for (i = l - 1; i >= 0; i--) {
        if (whitespace.indexOf(str.charAt(i)) === -1) {
            str = str.substring(0, i + 1);
            break
        }
    }

    return whitespace.indexOf(str.charAt(0)) === -1 ? str : ""
}

function toggleMultipleCheckbox(itemData, param_name, value, radio = false) {
    if (itemData[param_name] === null || itemData[param_name] === undefined || itemData[param_name].constructor !== Array) {
        itemData[param_name] = [];
    }
    if (radio) {
        itemData[param_name] = [value];
    } else {
        const i = (itemData[param_name].findIndex(v => v === value));
        if (i > -1) {
            itemData[param_name].splice(i, 1);
        } else {
            itemData[param_name].push(value);
        }
    }
    return itemData;
}

function snakeToCamel(str: string) {
    return str.toLowerCase().replace(/([-_][a-z])/g, group =>
        group
            .toUpperCase()
            .replace('-', '')
            .replace('_', '')
    );
}


function getDaysArrayBetween(start: string, end: string): Array<dayjs.Dayjs> {
    for (var arr = [], dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
        // @ts-ignore
        arr.push(dayjs(new Date(dt)));
    }
    return arr;
}

function weekCount(year: number, month_number: number) {

    // month_number is in the range 1..12

    var firstOfMonth = new Date(year, month_number - 1, 1);
    var lastOfMonth = new Date(year, month_number, 0);

    var used = firstOfMonth.getDay() + 6 + lastOfMonth.getDate();

    return Math.ceil(used / 7);
}

function buildQuery(data) {

    if (typeof (data) === 'string') return data;

    var query = [];

    for (var key in data) {
        if (data.hasOwnProperty(key)) {
            query.push(encodeURIComponent(key) + '=' + encodeURIComponent(data[key]));
        }
    }
    return query.join('&');
}


function parseCurrentURL(url: string): any {


    function assignParam(obj, key, value) {
        const keys = key.replace(/\]/g, '').split('[');
        let current = obj;

        keys.forEach((part, index) => {
            if (!current[part]) {
                current[part] = isNaN(keys[index + 1]) ? (keys.length - 1 === index ? value : {}) : [];
            }
            if (keys.length - 1 > index) {
                current = current[part];
            } else {
                current[part] = value;
            }
        });
    }

    const params = new URLSearchParams(url ? url.substring(url.indexOf('?')) : window.location.search);
    const result = {};

    for (const [key, value] of params.entries()) {
        assignParam(result, key, value);
    }

    return result;
}

function formatPrice(value: number, localeCode: string): string {
    const localeData: LocaleData | undefined = locales.find(l => l.code === localeCode && l.enabled);

    if (!localeData) {
        throw new Error(`Locale data not found or not enabled for code: ${localeCode}`);
    }

    return isNaN(value) ? "---" : new Intl.NumberFormat(localeData.locale, {
        style: 'currency',
        currency: localeData.currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    }).format(value);
}

function parseNumber(input: string | number) {
    return parseFloat(String(input).replace(',', '.').replace(/[^\d.-]/g, ''));
}


function switchCurrencyMark(currency: string, language: string | undefined) {
    if (currency.toLowerCase() === 'eur') {
        return "€";
    } else if (currency.toLowerCase() === 'czk' && language === 'cs') {
        return 'kč';
    }
    return currency;
}

export {
    capitalizeFirstLetter,
    generateRandomString,
    parseNumber,
    compareObjects,
    scrollToTop,
    numberWithSpaces,
    removeEmptyKeyValues,
    validateEmail,
    isIntersectionObserverEnabled,
    replaceEmptyValue,
    stripTags,
    formatCellphone,
    hrefCellphone,
    clearObserverObj,
    createWebUrl,
    getDistance,
    arraysEqual,
    wait,
    openUrl,
    timeSince,
    formatTime,
    replaceDashedKeys,
    roundNumber,
    toggleMultipleCheckbox,
    tyrionTrim,
    weekCount,
    getDaysArrayBetween,
    buildQuery,
    parseCurrentURL,
    switchCurrencyMark,
    buildUrlFromParams,
    formatPrice,
    getDefaultLocaleCurrency,
    snakeToCamel
};
