import {useState} from "#app";
import {UserWithoutPassword} from "../../lib/models/User";
import Client from "../../lib/models/Client";
import {computed} from "vue";

export const useAuthUser = () => {
  return useState('user', () => null)
}

export const getCurrentUser = () => {
  return computed(() => useAuthUser().value && useAuthUser().value.id > 0 ? (new Client()).fromObject(useAuthUser().value) : new Client());
}
