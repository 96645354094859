import BaseModel from "./BaseModel";
import {EMetaTagType} from "../enums/EMetaTagType";

export default class MetaTag extends BaseModel {
  private _type: EMetaTagType = EMetaTagType.META;
  private _key: string = "";
  private _value: string = "";

  public setAlternative (value: string) {
    if (this.value.length === 0) {
      this.value = value;
    }
    return this;
  }

  get type (): EMetaTagType {
    return this._type;
  }

  set type (value: EMetaTagType) {
    this._type = value;
  }

  get key (): string {
    return this._key;
  }

  set key (value: string) {
    this._key = value;
  }

  get value (): string {
    return this._value;
  }

  set value (value: string) {
    this._value = value;
  }
}
