import {TyrionApiResponse} from "~/lib/interfaces/TyrionApiResponse";
import {defineNuxtPlugin, useFetch, useRuntimeConfig} from "#app";
import CryptoJS from "crypto-js";



const decryptResponse = (response: TyrionApiResponse): TyrionApiResponse => {
    response.data = typeof response.data === 'string' ? JSON.parse(CryptoJS.RC4.decrypt(response.data as string, 'hch').toString(CryptoJS.enc.Utf8)) : response.data;
    return response;
}

const callSocketUrl = (url: string, method: string = 'GET', params: {} = {}) => {

    const encryptParams = useRuntimeConfig().public.production === true;

    return new Promise((resolve) => {

        url = (url.indexOf('/') !== 0 ? `/${url}` : url);

        params = {
            turl: url,
            m: method,
            params: params,
        };

        const hdata = encryptParams ? CryptoJS.RC4.encrypt(JSON.stringify(params), 'hch').toString() : params;

        useFetch('/api/socket/call', {
            onResponse({request, response, options}) {
                resolve({'status': 'success', 'response': response._data});
            },
            onResponseError({request, response, options}) {
                resolve({'status': 'error', 'response': response._data});
            },
            headers: {
                "Content-Type": "application/json;charset=UTF-8"
            },
            method: 'POST',
            lazy: false,
            key: url,
            immediate: false,
            body: hdata
        }).execute();

    }).then(data => {
        if (data.status === 'success' && typeof data.response === 'object') {
            data = (decryptResponse(data.response as TyrionApiResponse));
            if (!encryptParams && process.client) {
                console.log(data);
            }
            return data;
        } else {
            return data;
        }
    });
};

interface SocketApiCallData {
    body: Object,
    query: Object,
}

export default defineNuxtPlugin(nuxtApp => {

    return {
        provide: {
            socketApi: {
                getSocketUrl(url: string, data: SocketApiCallData | undefined = {}) {
                    return callSocketUrl(url, 'GET', data.query ? data.query : {});
                },
                postSocketUrl(url: string, data: SocketApiCallData | undefined = {}) {
                    return callSocketUrl(url, 'POST', data.body ? data.body : {});
                },
            }
        }
    }
})
