import BaseModel from "./BaseModel";
import {TyrionResourceImageType} from "../enums/TyrionResourceImageType";
import Product from "./Product";
import {useRuntimeConfig} from "#app";

export default class Image extends BaseModel {
    private _id: number = -1;
    private _id_tyrion: number = -1;
    private _alt: null | string = "";
    private _description: "";
    private _cfid: string = "";
    private _hidden: boolean = false;
    private _item_order: number | null = -1;
    private _link: string = "";
    private _name: string = "";
    private _size: 78828;
    private _src: string = "";
    private _title: string = "";
    private _code: string = "";
    private _type: string = "";
    private _video: boolean = false;
    private _video_id: string = "";
    private _video_link: string = "";
    private _video_type: string = "";
    private _default_for: string = "none";
    private _height: number = -1;
    private _width: number = 0;
    private _version: number = 0;
    private _created_at: string = "1970-01-01 00:00:01";
    private _updated_at: string = "1970-01-01 00:00:01";

    public isSvg(): boolean {
        return this.type === "svg";
    }

    get isTyrionImage() {
        return true;
    }

    public getTyrionSrc(type: TyrionResourceImageType, imageType?: string, apiUrlLink?: string): string {
        const apiUrl = apiUrlLink ? apiUrlLink : useRuntimeConfig().public.API_URL;

        if (this.CloudFlareId && !apiUrlLink) { // TODO Zmazat False
            const cfUrl = useRuntimeConfig().public.CLOUDFLARE_IMAGE_URL;
            const cfVariant = {
                'thumb': 'w500',
                'thumbw': 'w500',
                'thumb_m': 'w700',
                'thumb_mw': 'w700',
                'real': 'original',
                'cache': 'original',
                'cachew': 'original'
            };
            return cfUrl.replace('{id}', this.CloudFlareId).replace('{variant}', cfVariant[type] ?? type);
        } else {
            if (['w300', 'w500', 'w700'].indexOf(type) > -1) {
                type = TyrionResourceImageType.Thumb;
            } else if (['w900', 'w1200'].indexOf(type) > -1) {
                type = TyrionResourceImageType.ThumbM;
            } else if (['w1400', 'w1920'].indexOf(type) > -1) {
                type = TyrionResourceImageType.CacheSrc;
            }
            return `${apiUrl}/__image__/${this.src.indexOf("uploads/image") === 0 ? "local" : "remote"}/${type}/${this.code}__${this.id}_v${this.version}.${imageType ? imageType : this.type}`;
        }
    }

    public seoWidth(type: string): number {
        type = type === 'thumbw' ? 'thumb' : type;
        type = type === 'thumb_mw' ? 'thumb_m' : type;
        type = type === 'cache' ? 'real' : type;
        if (type === 'thumb') {
            return 560;
        } else if (type === 'thumb_m') {
            return 900;
        } else {
            return this.width;
        }
    }

    public seoHeight(type: string): number {
        type = type === 'thumbw' ? 'thumb' : type;
        type = type === 'thumb_mw' ? 'thumb_m' : type;
        type = type === 'cache' ? 'real' : type;
        const ratio = this.width / this.height;

        if (type === 'thumb') {
            return Math.round(560 / ratio);
        } else if (type === 'thumb_m') {
            return Math.round(900 / ratio);
        } else {
            return this.height;
        }

    }

    public getVueGalleryObject(product: Product | undefined): {} {
        return {
            id: this.id,
            href: this.getTyrionSrc(TyrionResourceImageType.CacheSrcWatermark),
            hrefWebp: this.getTyrionSrc(TyrionResourceImageType.CacheSrcWatermark, "webp"),
            thumbnail: this.getTyrionSrc(TyrionResourceImageType.ThumbWatermark),
            thumbnailWebp: this.getTyrionSrc(TyrionResourceImageType.ThumbWatermark, "webp"),
            title: this.title,
            type: this.type,
            alt: (product ? product.title : (this.alt ? this.alt : this.title)) + "-" + this.id
        };
    }

    get id(): number {
        return this._id > 0 ? this._id : this._id_tyrion;
    }

    set id(value: number) {
        this._id = value;
    }

    get alt(): string | null {
        return this._alt;
    }

    set alt(value: string | null) {
        this._alt = value;
    }

    get description(): "" {
        return this._description;
    }

    set description(value: "") {
        this._description = value;
    }

    get hidden(): boolean {
        return this._hidden;
    }

    set hidden(value: boolean) {
        this._hidden = value;
    }

    get item_order(): number | null {
        return this._item_order;
    }

    set item_order(value: number | null) {
        this._item_order = value;
    }

    get link(): string {
        return this._link;
    }

    set link(value: string) {
        this._link = value;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get size(): 78828 {
        return this._size;
    }

    set size(value: 78828) {
        this._size = value;
    }

    get src(): string {
        return this._src;
    }

    set src(value: string) {
        this._src = value;
    }

    get title(): string {
        return this._title;
    }

    set title(value: string) {
        this._title = value;
    }

    get type(): string {
        return this._type;
    }

    set type(value: string) {
        this._type = value;
    }

    get video(): boolean {
        return this._video;
    }

    set video(value: boolean) {
        this._video = value;
    }

    get video_id(): string {
        return this._video_id;
    }

    set video_id(value: string) {
        this._video_id = value;
    }

    get video_link(): string {
        return this._video_link;
    }

    set video_link(value: string) {
        this._video_link = value;
    }

    get video_type(): string {
        return this._video_type;
    }

    set video_type(value: string) {
        this._video_type = value;
    }

    get height(): number {
        return this._height;
    }

    set height(value: number) {
        this._height = value;
    }

    get width(): number {
        return this._width;
    }

    set width(value: number) {
        this._width = value;
    }

    get created_at(): string {
        return this._created_at;
    }

    set created_at(value: string) {
        this._created_at = value;
    }

    get updated_at(): string {
        return this._updated_at;
    }

    set updated_at(value: string) {
        this._updated_at = value;
    }

    get id_tyrion(): number {
        return this._id_tyrion;
    }

    set id_tyrion(value: number) {
        this._id_tyrion = value;
    }

    get code(): string {
        return this._code;
    }

    set code(value: string) {
        this._code = value;
    }

    get version(): number {
        return this._version;
    }

    set version(value: number) {
        this._version = value;
    }

    get default_for(): string {
        return this._default_for;
    }

    set default_for(value: string) {
        this._default_for = value;
    }

    get CloudFlareId(): string {
        return this._cfid;
    }


    get cfid(): string {
        return this._cfid;
    }

    set cfid(value: string) {
        this._cfid = value;
    }
}
