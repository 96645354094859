import Page from "~/lib/models/Page";
import {useRoute, useState} from "#app";
import {PageData} from "~/lib/interfaces/model/PageData";
import locales from "~/lib/data/locales.json";
import countries from "~/lib/data/web_countries.json";
import currencies from "~/lib/data/currencies.json";

export const useTyrionMasterPage = () => {

    return (new Page()).fromObject(useState('page').value as object) as Page;
}

export const obtainActiveLocale: () => { code: string, name: string, iso: string, file: string } = () => {

    const langCode = useState('page').value && useState('page').value.link && locales.find(l => l.iso === useState('page').value.link.substring(0, 2)) ? useState('page').value.link.substring(0, 2) : useRoute().path.substring(1, 3);
    return locales.find(l => l.iso === langCode) ? locales.find(l => l.iso === langCode) : locales.find(l => l.iso === 'sk');
}

export const obtainActiveLocaleCurrency: () => {
    code: string;
    name: string;
    mark: string;
    creditConversion: number;
    defaultLocale: string[];
    lang_names: {[k:string]: string};
} = () => {

    const langCode = obtainActiveLocale();
    return currencies.some(c => c.defaultLocale.some(nm => nm === langCode.code)) ? currencies.find(c => c.defaultLocale.some(nm => nm === langCode.code)) : currencies.find(c => c.code === 'EUR');
}

export const obtainActiveWebCountry: () => { code: string, name: string } = () => {

    const langCode = useState('page').value && useState('page').value.link && locales.find(l => l.iso === useState('page').value.link.substring(3, 5)) ? useState('page').value.link.substring(3, 5) : useRoute().path.substring(4, 6);

    return countries.find(l => l.code === langCode) ? countries.find(l => l.code === langCode) : countries.find(l => l.code === 'sk');
}

export const getPageDataHolder = (pageData: PageData, title: string) => {
    return pageData && pageData.holders ? pageData.holders.find(holder => (holder && holder.title === title)) : null;
}

export const getPageDataSection = (pageData: PageData, title: string) => {
    return pageData && pageData.sections ? pageData.sections.find(section => section.page.link.indexOf(title) > -1) : null;
}



