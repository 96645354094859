import {defineNuxtPlugin, useRuntimeConfig} from "#app";

Number.prototype.pad = function(n) {
    return ('0' + this).slice(-2);
}


export default defineNuxtPlugin(nuxtApp => {

    return {
        provide: {
            vysklonujSlovo: (zakladSlova: string, pocet: number, priponaA: string, priponaB: string, priponaSingle: string | undefined) => {
                if (priponaSingle !== undefined && pocet === 1) {
                    return zakladSlova + priponaSingle;
                } else if (pocet < 2) {
                    return zakladSlova;
                } else if (pocet > 4) {
                    return zakladSlova + priponaB;
                } else {
                    return zakladSlova + priponaA;
                }
            }
        }
    }
});
