import BaseModel from "./BaseModel";

export class ClientFavoriteItem extends BaseModel {
  private _id_accommodation: number;
  private _date_add: Date;

  get id_accommodation (): number {
    return this._id_accommodation;
  }

  set id_accommodation (value: number) {
    this._id_accommodation = value;
  }

  get date_add (): Date {
    return this._date_add;
  }

  set date_add (value: Date) {
    this._date_add = value;
  }
}
