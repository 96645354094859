import { default as _91subpage_937jrKb3Nl0RMeta } from "/var/www/hladamchatu.sk/nuxt/pages/[langcode]/[subpage].vue?macro=true";
import { default as _91_46_46_46all_936Q3FE2FrJBMeta } from "/var/www/hladamchatu.sk/nuxt/pages/[langcode]/[subsubpage]/[...all].vue?macro=true";
import { default as _91id_93PpEpn9IJXPMeta } from "/var/www/hladamchatu.sk/nuxt/pages/[langcode]/admin/accommodations/edit/[id].vue?macro=true";
import { default as indexsZjRUpYQL8Meta } from "/var/www/hladamchatu.sk/nuxt/pages/[langcode]/admin/accommodations/index.vue?macro=true";
import { default as _91link_938VoR3uSDOVMeta } from "/var/www/hladamchatu.sk/nuxt/pages/[langcode]/admin/accommodations/pricing/[link].vue?macro=true";
import { default as indexqakvDW7kkAMeta } from "/var/www/hladamchatu.sk/nuxt/pages/[langcode]/admin/accommodations/pricing/index.vue?macro=true";
import { default as _91link_93B9SjowTi4tMeta } from "/var/www/hladamchatu.sk/nuxt/pages/[langcode]/admin/accommodations/reviews/[link].vue?macro=true";
import { default as indexxrzXHtmtNrMeta } from "/var/www/hladamchatu.sk/nuxt/pages/[langcode]/admin/accommodations/reviews/index.vue?macro=true";
import { default as _91id_931g3PX6kXE4Meta } from "/var/www/hladamchatu.sk/nuxt/pages/[langcode]/admin/chat/room/[id].vue?macro=true";
import { default as indexB2oCIRIR0GMeta } from "/var/www/hladamchatu.sk/nuxt/pages/[langcode]/admin/chat/room/index.vue?macro=true";
import { default as indexCNRQ2KqpjWMeta } from "/var/www/hladamchatu.sk/nuxt/pages/[langcode]/admin/contact-forms/list/index.vue?macro=true";
import { default as helpcDyIQp7JArMeta } from "/var/www/hladamchatu.sk/nuxt/pages/[langcode]/admin/help.vue?macro=true";
import { default as indexXG2vdHT3sDMeta } from "/var/www/hladamchatu.sk/nuxt/pages/[langcode]/admin/index.vue?macro=true";
import { default as loginQ1sbRxxvwwMeta } from "/var/www/hladamchatu.sk/nuxt/pages/[langcode]/admin/login.vue?macro=true";
import { default as _91id_93LxTptXUD7NMeta } from "/var/www/hladamchatu.sk/nuxt/pages/[langcode]/admin/reservation/calendar/[id].vue?macro=true";
import { default as indexPkmWfUZAEhMeta } from "/var/www/hladamchatu.sk/nuxt/pages/[langcode]/admin/reservation/calendar/index.vue?macro=true";
import { default as _91id_93PiVr54GBX5Meta } from "/var/www/hladamchatu.sk/nuxt/pages/[langcode]/admin/reservation/list/[id].vue?macro=true";
import { default as index8pp3lmIRYnMeta } from "/var/www/hladamchatu.sk/nuxt/pages/[langcode]/admin/reservation/list/index.vue?macro=true";
import { default as type_selectqLFdu15G9nMeta } from "/var/www/hladamchatu.sk/nuxt/pages/[langcode]/admin/reservation/type_select.vue?macro=true";
import { default as sign_intZyX890xhmMeta } from "/var/www/hladamchatu.sk/nuxt/pages/[langcode]/admin/sign_in.vue?macro=true";
import { default as listgoVoNJTPqyMeta } from "/var/www/hladamchatu.sk/nuxt/pages/[langcode]/admin/synchronization/list.vue?macro=true";
import { default as tripsT45w6LVeYYMeta } from "/var/www/hladamchatu.sk/nuxt/pages/[langcode]/admin/trips.vue?macro=true";
import { default as activationVXZFrM18LAMeta } from "/var/www/hladamchatu.sk/nuxt/pages/[langcode]/admin/user/activation.vue?macro=true";
import { default as credentialslVja876ZqwMeta } from "/var/www/hladamchatu.sk/nuxt/pages/[langcode]/admin/user/credentials.vue?macro=true";
import { default as credit7XqwmVPGVvMeta } from "/var/www/hladamchatu.sk/nuxt/pages/[langcode]/admin/user/credit.vue?macro=true";
import { default as editAEpIHE0DuAMeta } from "/var/www/hladamchatu.sk/nuxt/pages/[langcode]/admin/user/edit.vue?macro=true";
import { default as indexl3KLzzGqyfMeta } from "/var/www/hladamchatu.sk/nuxt/pages/[langcode]/admin/user/index.vue?macro=true";
import { default as orderswxAgE8o5rFMeta } from "/var/www/hladamchatu.sk/nuxt/pages/[langcode]/admin/user/orders.vue?macro=true";
import { default as verifyrBxYjDvWL0Meta } from "/var/www/hladamchatu.sk/nuxt/pages/[langcode]/admin/user/verify.vue?macro=true";
import { default as indexsRWBnZNPNtMeta } from "/var/www/hladamchatu.sk/nuxt/pages/[langcode]/index.vue?macro=true";
import { default as _page_head_watcher0eP2YuOHiIMeta } from "/var/www/hladamchatu.sk/nuxt/pages/_page_head_watcher.vue?macro=true";
import { default as loginA7mYvXendGMeta } from "/var/www/hladamchatu.sk/nuxt/pages/admin/login.vue?macro=true";
import { default as sign_in3htAkIyfMyMeta } from "/var/www/hladamchatu.sk/nuxt/pages/admin/sign_in.vue?macro=true";
import { default as index0biMd7pVNlMeta } from "/var/www/hladamchatu.sk/nuxt/pages/index.vue?macro=true";
import { default as sentry_45example_45pagedbURyaZeuZMeta } from "/var/www/hladamchatu.sk/nuxt/pages/sentry-example-page.vue?macro=true";
import { default as web_pageaGCAPdalCJMeta } from "/var/www/hladamchatu.sk/nuxt/pages/web_page.ts?macro=true";
export default [
  {
    name: _91subpage_937jrKb3Nl0RMeta?.name ?? "langcode-subpage",
    path: _91subpage_937jrKb3Nl0RMeta?.path ?? "/:langcode()/:subpage()",
    meta: _91subpage_937jrKb3Nl0RMeta || {},
    alias: _91subpage_937jrKb3Nl0RMeta?.alias || [],
    redirect: _91subpage_937jrKb3Nl0RMeta?.redirect || undefined,
    component: () => import("/var/www/hladamchatu.sk/nuxt/pages/[langcode]/[subpage].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46all_936Q3FE2FrJBMeta?.name ?? "langcode-subsubpage-all",
    path: _91_46_46_46all_936Q3FE2FrJBMeta?.path ?? "/:langcode()/:subsubpage()/:all(.*)*",
    meta: _91_46_46_46all_936Q3FE2FrJBMeta || {},
    alias: _91_46_46_46all_936Q3FE2FrJBMeta?.alias || [],
    redirect: _91_46_46_46all_936Q3FE2FrJBMeta?.redirect || undefined,
    component: () => import("/var/www/hladamchatu.sk/nuxt/pages/[langcode]/[subsubpage]/[...all].vue").then(m => m.default || m)
  },
  {
    name: _91id_93PpEpn9IJXPMeta?.name ?? "langcode-admin-accommodations-edit-id",
    path: _91id_93PpEpn9IJXPMeta?.path ?? "/:langcode()/admin/accommodations/edit/:id()",
    meta: _91id_93PpEpn9IJXPMeta || {},
    alias: _91id_93PpEpn9IJXPMeta?.alias || [],
    redirect: _91id_93PpEpn9IJXPMeta?.redirect || undefined,
    component: () => import("/var/www/hladamchatu.sk/nuxt/pages/[langcode]/admin/accommodations/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: indexsZjRUpYQL8Meta?.name ?? "langcode-admin-accommodations",
    path: indexsZjRUpYQL8Meta?.path ?? "/:langcode()/admin/accommodations",
    meta: indexsZjRUpYQL8Meta || {},
    alias: indexsZjRUpYQL8Meta?.alias || [],
    redirect: indexsZjRUpYQL8Meta?.redirect || undefined,
    component: () => import("/var/www/hladamchatu.sk/nuxt/pages/[langcode]/admin/accommodations/index.vue").then(m => m.default || m)
  },
  {
    name: _91link_938VoR3uSDOVMeta?.name ?? "langcode-admin-accommodations-pricing-link",
    path: _91link_938VoR3uSDOVMeta?.path ?? "/:langcode()/admin/accommodations/pricing/:link()",
    meta: _91link_938VoR3uSDOVMeta || {},
    alias: _91link_938VoR3uSDOVMeta?.alias || [],
    redirect: _91link_938VoR3uSDOVMeta?.redirect || undefined,
    component: () => import("/var/www/hladamchatu.sk/nuxt/pages/[langcode]/admin/accommodations/pricing/[link].vue").then(m => m.default || m)
  },
  {
    name: indexqakvDW7kkAMeta?.name ?? "langcode-admin-accommodations-pricing",
    path: indexqakvDW7kkAMeta?.path ?? "/:langcode()/admin/accommodations/pricing",
    meta: indexqakvDW7kkAMeta || {},
    alias: indexqakvDW7kkAMeta?.alias || [],
    redirect: indexqakvDW7kkAMeta?.redirect || undefined,
    component: () => import("/var/www/hladamchatu.sk/nuxt/pages/[langcode]/admin/accommodations/pricing/index.vue").then(m => m.default || m)
  },
  {
    name: _91link_93B9SjowTi4tMeta?.name ?? "langcode-admin-accommodations-reviews-link",
    path: _91link_93B9SjowTi4tMeta?.path ?? "/:langcode()/admin/accommodations/reviews/:link()",
    meta: _91link_93B9SjowTi4tMeta || {},
    alias: _91link_93B9SjowTi4tMeta?.alias || [],
    redirect: _91link_93B9SjowTi4tMeta?.redirect || undefined,
    component: () => import("/var/www/hladamchatu.sk/nuxt/pages/[langcode]/admin/accommodations/reviews/[link].vue").then(m => m.default || m)
  },
  {
    name: indexxrzXHtmtNrMeta?.name ?? "langcode-admin-accommodations-reviews",
    path: indexxrzXHtmtNrMeta?.path ?? "/:langcode()/admin/accommodations/reviews",
    meta: indexxrzXHtmtNrMeta || {},
    alias: indexxrzXHtmtNrMeta?.alias || [],
    redirect: indexxrzXHtmtNrMeta?.redirect || undefined,
    component: () => import("/var/www/hladamchatu.sk/nuxt/pages/[langcode]/admin/accommodations/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_931g3PX6kXE4Meta?.name ?? "langcode-admin-chat-room-id",
    path: _91id_931g3PX6kXE4Meta?.path ?? "/:langcode()/admin/chat/room/:id()",
    meta: _91id_931g3PX6kXE4Meta || {},
    alias: _91id_931g3PX6kXE4Meta?.alias || [],
    redirect: _91id_931g3PX6kXE4Meta?.redirect || undefined,
    component: () => import("/var/www/hladamchatu.sk/nuxt/pages/[langcode]/admin/chat/room/[id].vue").then(m => m.default || m)
  },
  {
    name: indexB2oCIRIR0GMeta?.name ?? "langcode-admin-chat-room",
    path: indexB2oCIRIR0GMeta?.path ?? "/:langcode()/admin/chat/room",
    meta: indexB2oCIRIR0GMeta || {},
    alias: indexB2oCIRIR0GMeta?.alias || [],
    redirect: indexB2oCIRIR0GMeta?.redirect || undefined,
    component: () => import("/var/www/hladamchatu.sk/nuxt/pages/[langcode]/admin/chat/room/index.vue").then(m => m.default || m)
  },
  {
    name: indexCNRQ2KqpjWMeta?.name ?? "langcode-admin-contact-forms-list",
    path: indexCNRQ2KqpjWMeta?.path ?? "/:langcode()/admin/contact-forms/list",
    meta: indexCNRQ2KqpjWMeta || {},
    alias: indexCNRQ2KqpjWMeta?.alias || [],
    redirect: indexCNRQ2KqpjWMeta?.redirect || undefined,
    component: () => import("/var/www/hladamchatu.sk/nuxt/pages/[langcode]/admin/contact-forms/list/index.vue").then(m => m.default || m)
  },
  {
    name: helpcDyIQp7JArMeta?.name ?? "langcode-admin-help",
    path: helpcDyIQp7JArMeta?.path ?? "/:langcode()/admin/help",
    meta: helpcDyIQp7JArMeta || {},
    alias: helpcDyIQp7JArMeta?.alias || [],
    redirect: helpcDyIQp7JArMeta?.redirect || undefined,
    component: () => import("/var/www/hladamchatu.sk/nuxt/pages/[langcode]/admin/help.vue").then(m => m.default || m)
  },
  {
    name: indexXG2vdHT3sDMeta?.name ?? "langcode-admin",
    path: indexXG2vdHT3sDMeta?.path ?? "/:langcode()/admin",
    meta: indexXG2vdHT3sDMeta || {},
    alias: indexXG2vdHT3sDMeta?.alias || [],
    redirect: indexXG2vdHT3sDMeta?.redirect || undefined,
    component: () => import("/var/www/hladamchatu.sk/nuxt/pages/[langcode]/admin/index.vue").then(m => m.default || m)
  },
  {
    name: loginQ1sbRxxvwwMeta?.name ?? "langcode-admin-login",
    path: loginQ1sbRxxvwwMeta?.path ?? "/:langcode()/admin/login",
    meta: loginQ1sbRxxvwwMeta || {},
    alias: loginQ1sbRxxvwwMeta?.alias || [],
    redirect: loginQ1sbRxxvwwMeta?.redirect || undefined,
    component: () => import("/var/www/hladamchatu.sk/nuxt/pages/[langcode]/admin/login.vue").then(m => m.default || m)
  },
  {
    name: _91id_93LxTptXUD7NMeta?.name ?? "langcode-admin-reservation-calendar-id",
    path: _91id_93LxTptXUD7NMeta?.path ?? "/:langcode()/admin/reservation/calendar/:id()",
    meta: _91id_93LxTptXUD7NMeta || {},
    alias: _91id_93LxTptXUD7NMeta?.alias || [],
    redirect: _91id_93LxTptXUD7NMeta?.redirect || undefined,
    component: () => import("/var/www/hladamchatu.sk/nuxt/pages/[langcode]/admin/reservation/calendar/[id].vue").then(m => m.default || m)
  },
  {
    name: indexPkmWfUZAEhMeta?.name ?? "langcode-admin-reservation-calendar",
    path: indexPkmWfUZAEhMeta?.path ?? "/:langcode()/admin/reservation/calendar",
    meta: indexPkmWfUZAEhMeta || {},
    alias: indexPkmWfUZAEhMeta?.alias || [],
    redirect: indexPkmWfUZAEhMeta?.redirect || undefined,
    component: () => import("/var/www/hladamchatu.sk/nuxt/pages/[langcode]/admin/reservation/calendar/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93PiVr54GBX5Meta?.name ?? "langcode-admin-reservation-list-id",
    path: _91id_93PiVr54GBX5Meta?.path ?? "/:langcode()/admin/reservation/list/:id()",
    meta: _91id_93PiVr54GBX5Meta || {},
    alias: _91id_93PiVr54GBX5Meta?.alias || [],
    redirect: _91id_93PiVr54GBX5Meta?.redirect || undefined,
    component: () => import("/var/www/hladamchatu.sk/nuxt/pages/[langcode]/admin/reservation/list/[id].vue").then(m => m.default || m)
  },
  {
    name: index8pp3lmIRYnMeta?.name ?? "langcode-admin-reservation-list",
    path: index8pp3lmIRYnMeta?.path ?? "/:langcode()/admin/reservation/list",
    meta: index8pp3lmIRYnMeta || {},
    alias: index8pp3lmIRYnMeta?.alias || [],
    redirect: index8pp3lmIRYnMeta?.redirect || undefined,
    component: () => import("/var/www/hladamchatu.sk/nuxt/pages/[langcode]/admin/reservation/list/index.vue").then(m => m.default || m)
  },
  {
    name: type_selectqLFdu15G9nMeta?.name ?? "langcode-admin-reservation-type_select",
    path: type_selectqLFdu15G9nMeta?.path ?? "/:langcode()/admin/reservation/type_select",
    meta: type_selectqLFdu15G9nMeta || {},
    alias: type_selectqLFdu15G9nMeta?.alias || [],
    redirect: type_selectqLFdu15G9nMeta?.redirect || undefined,
    component: () => import("/var/www/hladamchatu.sk/nuxt/pages/[langcode]/admin/reservation/type_select.vue").then(m => m.default || m)
  },
  {
    name: sign_intZyX890xhmMeta?.name ?? "langcode-admin-sign_in",
    path: sign_intZyX890xhmMeta?.path ?? "/:langcode()/admin/sign_in",
    meta: sign_intZyX890xhmMeta || {},
    alias: sign_intZyX890xhmMeta?.alias || [],
    redirect: sign_intZyX890xhmMeta?.redirect || undefined,
    component: () => import("/var/www/hladamchatu.sk/nuxt/pages/[langcode]/admin/sign_in.vue").then(m => m.default || m)
  },
  {
    name: listgoVoNJTPqyMeta?.name ?? "langcode-admin-synchronization-list",
    path: listgoVoNJTPqyMeta?.path ?? "/:langcode()/admin/synchronization/list",
    meta: listgoVoNJTPqyMeta || {},
    alias: listgoVoNJTPqyMeta?.alias || [],
    redirect: listgoVoNJTPqyMeta?.redirect || undefined,
    component: () => import("/var/www/hladamchatu.sk/nuxt/pages/[langcode]/admin/synchronization/list.vue").then(m => m.default || m)
  },
  {
    name: tripsT45w6LVeYYMeta?.name ?? "langcode-admin-trips",
    path: tripsT45w6LVeYYMeta?.path ?? "/:langcode()/admin/trips",
    meta: tripsT45w6LVeYYMeta || {},
    alias: tripsT45w6LVeYYMeta?.alias || [],
    redirect: tripsT45w6LVeYYMeta?.redirect || undefined,
    component: () => import("/var/www/hladamchatu.sk/nuxt/pages/[langcode]/admin/trips.vue").then(m => m.default || m)
  },
  {
    name: activationVXZFrM18LAMeta?.name ?? "langcode-admin-user-activation",
    path: activationVXZFrM18LAMeta?.path ?? "/:langcode()/admin/user/activation",
    meta: activationVXZFrM18LAMeta || {},
    alias: activationVXZFrM18LAMeta?.alias || [],
    redirect: activationVXZFrM18LAMeta?.redirect || undefined,
    component: () => import("/var/www/hladamchatu.sk/nuxt/pages/[langcode]/admin/user/activation.vue").then(m => m.default || m)
  },
  {
    name: credentialslVja876ZqwMeta?.name ?? "langcode-admin-user-credentials",
    path: credentialslVja876ZqwMeta?.path ?? "/:langcode()/admin/user/credentials",
    meta: credentialslVja876ZqwMeta || {},
    alias: credentialslVja876ZqwMeta?.alias || [],
    redirect: credentialslVja876ZqwMeta?.redirect || undefined,
    component: () => import("/var/www/hladamchatu.sk/nuxt/pages/[langcode]/admin/user/credentials.vue").then(m => m.default || m)
  },
  {
    name: credit7XqwmVPGVvMeta?.name ?? "langcode-admin-user-credit",
    path: credit7XqwmVPGVvMeta?.path ?? "/:langcode()/admin/user/credit",
    meta: credit7XqwmVPGVvMeta || {},
    alias: credit7XqwmVPGVvMeta?.alias || [],
    redirect: credit7XqwmVPGVvMeta?.redirect || undefined,
    component: () => import("/var/www/hladamchatu.sk/nuxt/pages/[langcode]/admin/user/credit.vue").then(m => m.default || m)
  },
  {
    name: editAEpIHE0DuAMeta?.name ?? "langcode-admin-user-edit",
    path: editAEpIHE0DuAMeta?.path ?? "/:langcode()/admin/user/edit",
    meta: editAEpIHE0DuAMeta || {},
    alias: editAEpIHE0DuAMeta?.alias || [],
    redirect: editAEpIHE0DuAMeta?.redirect || undefined,
    component: () => import("/var/www/hladamchatu.sk/nuxt/pages/[langcode]/admin/user/edit.vue").then(m => m.default || m)
  },
  {
    name: indexl3KLzzGqyfMeta?.name ?? "langcode-admin-user",
    path: indexl3KLzzGqyfMeta?.path ?? "/:langcode()/admin/user",
    meta: indexl3KLzzGqyfMeta || {},
    alias: indexl3KLzzGqyfMeta?.alias || [],
    redirect: indexl3KLzzGqyfMeta?.redirect || undefined,
    component: () => import("/var/www/hladamchatu.sk/nuxt/pages/[langcode]/admin/user/index.vue").then(m => m.default || m)
  },
  {
    name: orderswxAgE8o5rFMeta?.name ?? "langcode-admin-user-orders",
    path: orderswxAgE8o5rFMeta?.path ?? "/:langcode()/admin/user/orders",
    meta: orderswxAgE8o5rFMeta || {},
    alias: orderswxAgE8o5rFMeta?.alias || [],
    redirect: orderswxAgE8o5rFMeta?.redirect || undefined,
    component: () => import("/var/www/hladamchatu.sk/nuxt/pages/[langcode]/admin/user/orders.vue").then(m => m.default || m)
  },
  {
    name: verifyrBxYjDvWL0Meta?.name ?? "langcode-admin-user-verify",
    path: verifyrBxYjDvWL0Meta?.path ?? "/:langcode()/admin/user/verify",
    meta: verifyrBxYjDvWL0Meta || {},
    alias: verifyrBxYjDvWL0Meta?.alias || [],
    redirect: verifyrBxYjDvWL0Meta?.redirect || undefined,
    component: () => import("/var/www/hladamchatu.sk/nuxt/pages/[langcode]/admin/user/verify.vue").then(m => m.default || m)
  },
  {
    name: indexsRWBnZNPNtMeta?.name ?? "langcode",
    path: indexsRWBnZNPNtMeta?.path ?? "/:langcode()",
    meta: indexsRWBnZNPNtMeta || {},
    alias: indexsRWBnZNPNtMeta?.alias || [],
    redirect: indexsRWBnZNPNtMeta?.redirect || undefined,
    component: () => import("/var/www/hladamchatu.sk/nuxt/pages/[langcode]/index.vue").then(m => m.default || m)
  },
  {
    name: _page_head_watcher0eP2YuOHiIMeta?.name ?? "_page_head_watcher",
    path: _page_head_watcher0eP2YuOHiIMeta?.path ?? "/_page_head_watcher",
    meta: _page_head_watcher0eP2YuOHiIMeta || {},
    alias: _page_head_watcher0eP2YuOHiIMeta?.alias || [],
    redirect: _page_head_watcher0eP2YuOHiIMeta?.redirect || undefined,
    component: () => import("/var/www/hladamchatu.sk/nuxt/pages/_page_head_watcher.vue").then(m => m.default || m)
  },
  {
    name: loginA7mYvXendGMeta?.name ?? "admin-login",
    path: loginA7mYvXendGMeta?.path ?? "/admin/login",
    meta: loginA7mYvXendGMeta || {},
    alias: loginA7mYvXendGMeta?.alias || [],
    redirect: loginA7mYvXendGMeta?.redirect || undefined,
    component: () => import("/var/www/hladamchatu.sk/nuxt/pages/admin/login.vue").then(m => m.default || m)
  },
  {
    name: sign_in3htAkIyfMyMeta?.name ?? "admin-sign_in",
    path: sign_in3htAkIyfMyMeta?.path ?? "/admin/sign_in",
    meta: sign_in3htAkIyfMyMeta || {},
    alias: sign_in3htAkIyfMyMeta?.alias || [],
    redirect: sign_in3htAkIyfMyMeta?.redirect || undefined,
    component: () => import("/var/www/hladamchatu.sk/nuxt/pages/admin/sign_in.vue").then(m => m.default || m)
  },
  {
    name: index0biMd7pVNlMeta?.name ?? "index",
    path: index0biMd7pVNlMeta?.path ?? "/",
    meta: index0biMd7pVNlMeta || {},
    alias: index0biMd7pVNlMeta?.alias || [],
    redirect: index0biMd7pVNlMeta?.redirect || undefined,
    component: () => import("/var/www/hladamchatu.sk/nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: sentry_45example_45pagedbURyaZeuZMeta?.name ?? "sentry-example-page",
    path: sentry_45example_45pagedbURyaZeuZMeta?.path ?? "/sentry-example-page",
    meta: sentry_45example_45pagedbURyaZeuZMeta || {},
    alias: sentry_45example_45pagedbURyaZeuZMeta?.alias || [],
    redirect: sentry_45example_45pagedbURyaZeuZMeta?.redirect || undefined,
    component: () => import("/var/www/hladamchatu.sk/nuxt/pages/sentry-example-page.vue").then(m => m.default || m)
  },
  {
    name: web_pageaGCAPdalCJMeta?.name ?? "web_page",
    path: web_pageaGCAPdalCJMeta?.path ?? "/web_page",
    meta: web_pageaGCAPdalCJMeta || {},
    alias: web_pageaGCAPdalCJMeta?.alias || [],
    redirect: web_pageaGCAPdalCJMeta?.redirect || undefined,
    component: () => import("/var/www/hladamchatu.sk/nuxt/pages/web_page.ts").then(m => m.default || m)
  }
]