import {useAuthUser} from './useAuthUser'
import {useNuxtApp, useRequestHeaders, useRuntimeConfig} from "#app";
import CryptoJS from "crypto-js";
import {ClientFavoriteItem} from "../../lib/models/ClientFavoriteItem";
import {useStateFunctionFavorites} from "../state/favorites";

export const useAuth = () => {
    const authUser = useAuthUser()
    const encryptParams = useRuntimeConfig().public.production === true;
    const {$socketIo} = useNuxtApp();
    const {favorites} = useStateFunctionFavorites();

    const setUser = (user: any) => {
        authUser.value = user
    }


    const login = async (
        email: string,
        phone: string,
        password: string,
        rememberMe: boolean,
    ) => {


        const params = {
            email,
            phone,
            password,
            rememberMe,
        };
        const hdata = encryptParams ? CryptoJS.RC4.encrypt(JSON.stringify(params), 'hch').toString() : params;

        const data = await $fetch('/auth/login', {
            method: 'POST',
            body: hdata,
        })

        await me();
        if (authUser.value) {
            $socketIo.reconnect();
        }
        return authUser
    }

    const loginOAuth = async (
        token: string,
        provider: string,
        existing: boolean
    ) => {
        
        const params = {
            token: token,
            provider: provider,
            existing: existing === true
        };


        if(existing){
            await me();
            params.user_token = authUser.value.token;
        }

        const hdata = encryptParams ? CryptoJS.RC4.encrypt(JSON.stringify(params), 'hch').toString() : params;

        const data = await $fetch('/auth/login-oauth', {
            method: 'POST',
            body: hdata,
        });
        console.log(data);

        await me();
        if (authUser.value) {
            $socketIo.reconnect();
        }
        return authUser
    }

    const logoutOAuth = async (
        provider: string
    ) => {
        
        const params = {
            token: authUser.value.token,
            provider: provider
        };
        const hdata = encryptParams ? CryptoJS.RC4.encrypt(JSON.stringify(params), 'hch').toString() : params;

        const data = await $fetch('/auth/disconnect-oauth', {
            method: 'POST',
            body: hdata,
        })

        await me();
        return authUser
    }

    const logout = async () => {
        const data = await $fetch('/auth/logout', {
            method: 'POST',
        })

        $socketIo.disconnect();
        setUser(data.user);
    }

    const me = async (force = false) => {
        if (!authUser.value || force) {
            try {
                const data = await $fetch('/auth/me', {
                    headers: useRequestHeaders(['cookie']) as HeadersInit,
                });

                if (data.user && data.user.favorites) {
                    favorites.value = data.user.favorites.map((item: ClientFavoriteItem) => {
                        return {
                            id: Number(item.id_accommodation)
                        }
                    });
                }


                setUser(data.user);
            } catch (error) {
                setUser(null);
            }
        }

        return authUser
    }

    return {
        login,
        loginOAuth,
        logoutOAuth,
        logout,
        me,
    }
}
