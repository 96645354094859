import {TyrionApiResponse} from "~/lib/interfaces/TyrionApiResponse";
import {defineNuxtPlugin, useFetch, useRuntimeConfig} from "#app";
import CryptoJS from "crypto-js";
import {Obj} from "@popperjs/core";


const decryptResponse = (response: TyrionApiResponse): TyrionApiResponse => {
    response.data = typeof response.data === 'string' ? JSON.parse(CryptoJS.RC4.decrypt(response.data as string, 'hch').toString(CryptoJS.enc.Utf8)) : response.data;
    return response;
}

const callTyrionUrl = (url: string, method: string = 'GET', params: {} = {}, headers: {} = {}, cacheTime: number = 0) => {

    const cacheEnabled = useRuntimeConfig().public.CACHE_API_ENABLE;
    const encryptParams = useRuntimeConfig().public.production === true;

    return new Promise((resolve) => {

        url = (url.indexOf('/') !== 0 ? `/${url}` : url);

        params = {
            turl: url,
            m: method,
            params: params,
            cacheTime: cacheEnabled ? cacheTime : 0
        };

        const hdata = encryptParams ? CryptoJS.RC4.encrypt(JSON.stringify(params), 'hch').toString() : params;

        useFetch('/api/tyrion/call', {
            onResponse({request, response, options}) {
                resolve({'status': 'success', 'response': response._data});
            },
            onResponseError({request, response, options}) {
                resolve({'status': 'error', 'response': response._data});
            },
            headers: headers && Object.keys(headers).length > 0 ? headers : {
                "Content-Type": "application/json;charset=UTF-8"
            },
            method: 'POST',
            lazy: false,
            key: url,
            immediate: false,
            body: hdata
        }).execute();
    }).then(data => {
        if (data.status === 'success') {
            data = (decryptResponse(data.response as TyrionApiResponse));
            if (!encryptParams && process.client) {
                console.log(data);
            }
            return data;
        } else {
            return data.response;
        }
    });
};

interface TyrionApiCallData {
    body: Object,
    query: Object,
    headers: Object,
    clientToken?: String | undefined,
    cacheTime?: number | undefined
    userTokenRequired?: Boolean | undefined
}

export default defineNuxtPlugin(nuxtApp => {

    return {
        provide: {
            tyrionApi: {
                postTyrionUrl(url: string, data: TyrionApiCallData | undefined = {}) {
                    return callTyrionUrl(url, 'POST', data.body ? data.body : {}, data.userTokenRequired ? {
                        "Authentication": "UserBearer",
                        "Content-Type": "application/json;charset=UTF-8"
                    } : {}, data.cacheTime);
                },
                getTyrionUrl(url: string, data: TyrionApiCallData | undefined = {}) {
                    return callTyrionUrl(url, 'GET', data.query ? data.query : {}, data.userTokenRequired ? {
                        "Authentication": "UserBearer",
                        "Content-Type": "application/json;charset=UTF-8"
                    } : {}, data.cacheTime);
                },
                getApiUrl() {
                    const config = useRuntimeConfig();
                    return config.public.API_URL;
                },

            }
        }
    }
})
