<template>
  <NuxtLayout>
    <NuxtLoadingIndicator/>
    <div class="route-change-preloader" :class="{visible: loading}">
      <SvgIconLogoCZ class="logo" v-if="activeCountry.code === 'cs'"/>
      <SvgIconLogoPL class="logo" v-else-if="activeCountry.code === 'pl'"/>
      <SvgIconLogoHU class="logo" v-else-if="activeCountry.code === 'hu'"/>
      <SvgIconLogo class="logo" v-else/>
      <div class="preloader"></div>
    </div>
    <NuxtPage/>
  </NuxtLayout>
</template>

<script setup lang="ts">
import {ref} from "vue";
import {useNuxtApp} from "#app";
import SvgIconLogo from "~/assets/img/logo.svg";
import SvgIconLogoCZ from "~/assets/img/logo_cs.svg";
import SvgIconLogoPL from "~/assets/img/logo_pl.svg";
import SvgIconLogoHU from "~/assets/img/logo_hu.svg";
import {obtainActiveWebCountry} from "#imports";

const nuxtApp = useNuxtApp();
const activeCountry = obtainActiveWebCountry();
const loading = ref(false);
const currentVersion = '1.0.4';

const registerServiceWorker = () => {
    if (!('serviceWorker' in navigator)) {
        console.log('serviceWorker is not supported in current browser!')
    } else {
      navigator.serviceWorker.register('/sw3.js').then(registration => {
        const savedVersion = localStorage.getItem('appVersion');

        if (savedVersion !== currentVersion) {
          localStorage.setItem('appVersion', currentVersion);

          registration.unregister().then(() => {
            window.location.reload();
          });
        }

        setInterval(() => {
          registration.update();
        }, 60 * 60 * 1000);

        registration.update();

      }).catch(error => {
        console.error('Error during service worker registration:', error);
      });
    }
};

const unregisterServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then(registrations => {
      registrations.forEach(registration => {
        registration.unregister().then(success => {
          console.log('Service worker unregistered successfully:', success);
        }).catch(error => {
          console.error('Failed to unregister service worker:', error);
        });
      });
    });
  }
};

if (process.client) {
    // registerServiceWorker();
    unregisterServiceWorker();

    nuxtApp.hook("page:start", () => {
        loading.value = true;
    });

    nuxtApp.hook("page:finish", () => {
        loading.value = false;
    });
}


</script>
