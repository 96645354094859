import {defineNuxtPlugin, useAsyncData} from "#app";

import locales from "../lib/data/locales.json";
import {createWebUrl} from "../lib/functions";
import {shallowRef} from "vue";


export default defineNuxtPlugin(async (nuxtApp) => {

    const locations = locales.filter((l: {
        code: string
    }) => ['sk', 'en', 'cs', 'pl', 'hu', 'de'].indexOf(l.code) >= 0);
    const url = nuxtApp.$router.currentRoute.value.path.substring(1);
    let langCode = url.indexOf('/') > 0 ? url.substring(0, url.indexOf('/')) : url;
    langCode = langCode.indexOf('-') > -1 ? langCode.substring(0, langCode.indexOf('-')) : langCode;
    langCode = locations.find(l => l.code === langCode) ? langCode : 'sk';

    const inscriptions = shallowRef({load: 'Loading'});

    try {
        const data = await $fetch('/inscriptions/load?lang=' + langCode);
        inscriptions.value = data.data;
    } catch (e) {
        inscriptions.value = {};
    }

    const i18n = () => {
        const t = (text: string, altText: string | undefined = undefined) => {
            if (text.length < 150) {
                if (inscriptions.value[createWebUrl(text)] === undefined) {
                    if (altText !== undefined) {
                        if (inscriptions.value[createWebUrl(altText)] === undefined) {
                            console.log('Unable to find inscription for ' + altText);
                            return createWebUrl(altText);
                        } else {
                            return inscriptions.value[createWebUrl(altText)];
                        }
                    } else {
                        console.log('Unable to find inscription for ' + text);
                        return createWebUrl(text);
                    }
                } else {
                    return inscriptions.value[createWebUrl(text)];


                }
            } else {
                console.log(text);
                return "Unknown inscription error";
            }
        }
        return {t};
    }

    return {
        provide: {
            i18n: i18n(),
            t: (text: string, altText: string | undefined) => i18n().t(text, altText)
        }
    }
})
