import {VueReCaptcha} from 'vue-recaptcha-v3';
import {useRuntimeConfig, defineNuxtPlugin} from "#app";
import {IReCaptchaOptions} from "vue-recaptcha-v3/dist/IReCaptchaOptions";

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.use(VueReCaptcha, {
        siteKey: useRuntimeConfig().public.CAPTCHA_KEY,
        loaderOptions: {
            autoHideBadge: true,
            explicitRenderParameters: {
                badge: 'bottomleft',
            },
        },
    } as IReCaptchaOptions);
});
