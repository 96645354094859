import BaseModel from "./BaseModel";
import Image from "./Image";
import Address from "./Address";
import dayjs from "dayjs";
import {ClientFavoriteItem} from "./ClientFavoriteItem";
import ClientNotification from "./ClientNotification";
import SimpleClientFollower from "./SimpleClientFollower";
import {formatCellphone, hrefCellphone} from "../functions";
import ClientCredit from "./ClientCredit";

export default class Client extends BaseModel {
    private _id: number;
    private _token: string;
    private _socket_token: string;
    private _client_name: string = "";
    private _alias: string = "";
    private _code: string;
    private _phone: string;
    private _web: string;
    private _type: string = "client";
    private _ico: string;
    private _dic: string;
    private _disabled: string;
    private _ic_dph: string;
    private _note: string;
    private _email: string;
    private _verified: boolean;
    private _activated: boolean | string = false;
    private _password: string;
    private _recommended_key: string | null;
    private _billingAddress: Address = new Address();
    private _created_at: Date | null = null;
    private _birth_date: string | null = null;
    private _parent: Client | null = null;
    private _logo: Image | null = new Image();
    private _credit: ClientCredit = new ClientCredit();
    private _favorites: Array<ClientFavoriteItem> = [];
    private _likes: Array<number> = [];
    private _notifications: Array<ClientNotification> = [];
    private _following_clients: Array<SimpleClientFollower> = [];
    private _params: { [k: string]: any } = {};
    private _oath_providers: { [k: string]: boolean } = {};
    private _oath_info: { [k: string]: [k: string] } = {};

    public isNew(): boolean {
        return !(this.id > 0);
    }

    get hasLogo() {
        return this.logo !== null && Number(this.logo.id) > 0;
    }

    public fromObject(jsonObj: object): BaseModel {
        return super.fromObject(jsonObj, {
            favorites: (item => {
                return (new ClientFavoriteItem()).fromObject(item);
            }),
            notifications: (item => {
                return (new ClientNotification()).fromObject(item);
            }),
            following_clients: (item => {
                return (new SimpleClientFollower()).fromObject(item);
            })
        });
    }

    public getAgeInDays(): number {
        if (this.created_at !== null) {
            return dayjs().diff(dayjs(this.created_at), "day");
        } else {
            return -1;
        }
    }

    public isBillingAddressFilled() {
        return this.billingAddress !== null && this.billingAddress.city !== null && this.billingAddress.city.length > 0;
    }

    public isParentBillingAddressFilled() {
        return this.parent && this.parent.billingAddress && this.parent.billingAddress.city !== null && this.parent.billingAddress.city.length > 0;
    }

    public hasCustomParam(key: string) {
        return this.params.hasOwnProperty(key) && this.params[key] !== undefined && this.params[key] !== null && String(this.params[key]).length > 0;
    }

    public getCustomParam(key: string): any {
        return this.params[key];
    }

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get code(): string {
        return this._code;
    }

    set code(value: string) {
        this._code = value;
    }

    get token(): string {
        return this._token;
    }

    set token(value: string) {
        this._token = value;
    }

    get socket_token(): string {
        return this._socket_token;
    }

    set socket_token(value: string) {
        this._socket_token = value;
    }

    get client_name(): string {
        return this._client_name;
    }

    set client_name(value: string) {
        this._client_name = value;
    }

    get phone(): string {
        return this._phone;
    }

    set phone(value: string) {
        this._phone = value;
    }

    get formattedCellphone(): string {
        return formatCellphone(this.phone);
    }

    get hrefCellphone(): string {
        return hrefCellphone(this.phone);
    }

    get birth_date(): string | null {
        return this._birth_date;
    }

    set birth_date(value: string | null) {
        this._birth_date = value;
    }

    get web(): string {
        return this._web;
    }

    set web(value: string) {
        this._web = value;
    }

    get type(): string {
        return this._type;
    }

    set type(value: string) {
        this._type = value;
    }

    get ico(): string {
        return this._ico;
    }

    set ico(value: string) {
        this._ico = value;
    }

    get dic(): string {
        return this._dic;
    }

    set dic(value: string) {
        this._dic = value;
    }

    get ic_dph(): string {
        return this._ic_dph;
    }

    set ic_dph(value: string) {
        this._ic_dph = value;
    }

    get note(): string {
        return this._note;
    }

    set note(value: string) {
        this._note = value;
    }

    get logo(): Image | null {
        return this._logo;
    }

    set logo(value: Image | null) {
        this._logo = value;
    }

    get email(): string {
        return this._email;
    }

    set email(value: string) {
        this._email = value;
    }

    get billingAddress(): Address {
        return this._billingAddress;
    }

    set billingAddress(value: Address) {
        this._billingAddress = value;
    }

    get disabled(): string {
        return this._disabled;
    }

    set disabled(value: string) {
        this._disabled = value;
    }

    get verified(): boolean {
        return this._verified;
    }

    set verified(value: boolean) {
        this._verified = value;
    }

    get password(): string {
        return this._password;
    }

    set password(value: string) {
        this._password = value;
    }

    get params(): { [p: string]: any } {
        return this._params;
    }

    set params(value: { [p: string]: any }) {
        this._params = value;
    }

    get credit(): ClientCredit {
        return this._credit;
    }

    set credit(value: ClientCredit) {
        this._credit = value;
    }

    get created_at(): Date | null {
        return this._created_at;
    }

    set created_at(value: Date | null) {
        this._created_at = value;
    }

    get parent(): Client | null {
        return this._parent;
    }

    set parent(value: Client | null) {
        this._parent = value;
    }

    get recommended_key(): string | null {
        return this._recommended_key;
    }

    set recommended_key(value: string | null) {
        this._recommended_key = value;
    }

    public getRecommendedKey() {
        return this.recommended_key ? this.recommended_key : this.email;
    }

    get favorites(): Array<ClientFavoriteItem> {
        return this._favorites;
    }

    get likes(): Array<number> {
        return this._likes;
    }

    set favorites(value: Array<ClientFavoriteItem>) {
        this._favorites = value;
    }

    set likes(value: Array<number>) {
        this._likes = value;
    }

    get notifications(): Array<ClientNotification> {
        return this._notifications;
    }

    set notifications(value: Array<ClientNotification>) {
        this._notifications = value;
    }

    get following_clients(): Array<SimpleClientFollower> {
        return this._following_clients;
    }

    set following_clients(value: Array<SimpleClientFollower>) {
        this._following_clients = value;
    }

    get activated(): boolean | string {
        return this._activated;
    }

    set activated(value: boolean | string) {
        this._activated = value;
    }

    get alias(): string {
        return this._alias;
    }

    set alias(value: string) {
        this._alias = value;
    }


    get oath_providers(): { [p: string]: boolean } {
        return this._oath_providers;
    }

    set oath_providers(value: { [p: string]: boolean }) {
        this._oath_providers = value;
    }


    get oath_info(): { [p: string]: [k: string] } {
        return this._oath_info;
    }

    set oath_info(value: { [p: string]: [k: string] }) {
        this._oath_info = value;
    }
}
