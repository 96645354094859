export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"theme-color","content":"#87953e"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.ico"},{"rel":"dns-prefetch","type":"application/json","crossorigin":"","href":"https://api.hladamchatu.sk"},{"rel":"dns-prefetch","crossorigin":"","href":"https://www.google-analytics.com"},{"rel":"dns-prefetch","crossorigin":"","href":"https://connect.facebook.net"},{"hid":"icon","rel":"icon","type":"image/png","href":"/favicon-32x32.png"},{"hid":"apple-touch-icon","rel":"apple-touch-icon","href":"/apple-touch-icon.png"},{"rel":"manifest","href":"/manifest.json"}],"style":[],"script":[{"hid":"gtm","src":"https://www.googletagmanager.com/gtm.js?id=GTM-P839R6L","async":true}],"noscript":[]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = false

export const devPagesDir = null

export const devRootDir = null