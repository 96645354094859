export default abstract class BaseModel {

  public fromObject (jsonObj: object, arrayProperties: { [k: string]: (item: {}) => BaseModel } = {}): BaseModel {

    for (const propName in jsonObj) {
      if (jsonObj.hasOwnProperty(propName)) {

        if (propName !== null) {

          if (this[propName] instanceof BaseModel) {
            const tModel = this[propName] as BaseModel;
            this[propName] = tModel.fromObject(jsonObj[propName]);
          } else if (this[propName] instanceof Array && arrayProperties[propName] !== undefined) {
            const mirrorData: Array<BaseModel> = [];
            jsonObj[propName].forEach(item => {
              mirrorData.push(arrayProperties[propName](item));
            });
            this[propName] = mirrorData;
          } else if (this[propName] !== undefined && this[propName] !== null && this[propName].constructor === Array && jsonObj[propName] && jsonObj[propName].constructor === Array && !jsonObj[propName].some(i => typeof i !== "number")) {
            this[propName] = jsonObj[propName];
          } else if (typeof this[propName] === "object" && jsonObj[propName] && jsonObj[propName].constructor === Array) {
            this[propName] = jsonObj[propName];
            // this[propName] = Object.assign({}, jsonObj[propName]);
          } else {
            this[propName] = jsonObj[propName];
          }
        }
      }
    }
    return this;
  }
}
